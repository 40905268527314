<template>
	<w-layout column fill-height>
		<w-flex v-for="family in families" :key="family.id" shrink>
			<NotificationItem :value="family" />
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

import NotificationsModuleGuard from '@/mixins/ModulesGuards/Notifications/NotificationsModuleGuard'

export default {
	name: 'BasicSettings',
	components: {
		NotificationItem: () => ({
			component: import('@/components/Notifications/Profile/NotificationItem')
		})
	},
	mixins: [NotificationsModuleGuard],
	computed: {
		...mapState({
			families: state => state.notifications.families
		})
	}
}
</script>
